export const ACCOUNT = '/account';
export const FORGOT_PASSWORD = '/forgot-password';
export const HOME = '/';
export const PRODUCTS = '/products';
export const PRODUCT_UPDATE = '/update-product';
export const REGISTER = '/register';
export const ROLES = '/roles';
export const SIGNIN = '/sign-in';
export const USER_CREATE = '/create-user';
export const USER_UPDATE = '/update-user';
export const USERS = '/users';
export const USER = '/user';
export const SPLASH = '/splash';
export const INVOICES = '/invoices';
export const INVOICE_DETAIL = '/invoices-detail';
export const BIKES = '/bikes';
export const ROUTESMAPS = '/routes/mapview';
export const ROUTES = '/routes';
export const RIDERS = '/riders';
export const RIDERS_PROCOS = '/riders/procos';
export const PROCOS_UPDATE = '/update-proco';
export const COMMISSIONS_BY_RIDER = '/riders/commissions';
export const ORDERS = '/orders';
export const ORDERS_PICKUP = '/orders/orders-pickup';
export const ORDERS_PICKUP_CONFIRMATION = '/orders/confirmation-pickup';
export const ORDERS_PAYMENTS_CONFIRMATION = '/orders/confirmation-payments';
export const ADDRESSES = '/addresses';
export const ROADMAPS = '/roadmaps';
export const ADDRESSESMAPS = '/addresses/mapview';
export const CONFIG = '/config';
export const DEPARTMENTS = '/config/departments';
export const CONFIG_MESSAGES = '/config/messages';
export const NOTIFICATIONS = '/notifications';
export const CONFIG_COMMISSIONS = '/config/commissions';
export const REPORTS = '/reports';
export const REPORTSG = '/reportsG';
export const CART_VALIDATION = '/cart-validation';
export const DASH_ORDERS = '/dash-orders';
export const FINANCE = '/finance';
export const WALLET = '/finance/wallet';
export const REI = '/finance/rei';
export const FINANCE_PAGO_MOVIL = '/finance/finance-pago-movil';
export const FINANCE_VALIDATE_PAY = '/finance/finance-validate-pay';
export const COMMERCE_TO_PERSON = '/finance/commerce-to-person';
export const COMMERCE_TO_PERSON_REPORT = '/finance/commerce-to-person-report';
export const MARKETING = '/marketing';
export const MARKETING_EMAIL = '/marketing/email';
export const MARKETING_SMS = '/marketing/sms';
export const BANNERS = '/marketing/banners';
export const CONFIG_ANNOUNCEMENTS = '/marketing/modal-config';
export const COUPONS = '/coupons';
export const STORAGE = '/storage';
export const STORAGE_PACKING = '/storage/packing';
export const STORAGE_BILLING = '/storage/billing';
export const STORAGE_PHARMACY = '/storage/pharmacy';
export const STORAGE_PROCOS = '/storage/procos';
export const SPECIAL_CATEGORIES = '/special-categories';
export const STORAGE_STATUS_PICKING = '/storage/picking-status';
export const MIN_MAX = '/minimos-maximos';
export const REFERRED = '/referred';
export const PICTURES = '/pictures';
export const PICTURES_UPLOADING = '/pictures/upload';
export const PICTURES_REMOVAL = '/pictures/removal';
export const PICTURES_EXCHANGE = '/pictures/exchange';
