export const ADMINS = 'admins';
export const ORDERS = 'orders';
export const USERS = 'users';
export const RIDERS = 'riders';
export const COMMISSIONS_BY_RIDERS = 'commissionsByRider';
export const ROLES = 'roles';
export const PERMISSIONS = 'permissions';
export const DEPARTAMENTS = 'departments';
export const NOTIFICATIONS = 'notifications';
export const ROADMAPS = 'roadmaps';
export const ROUTES = 'routes';
export const ADDRESSES = 'addresses';
export const CONFIG = 'config';
export const PROCO = 'proco';
export const FINANCE = 'finance';
export const MAIL = 'mail';
export const TEMPLATES = 'templates';
export const COUPONS = 'coupons';
export const SPECIAL_CATEGORIES = 'special';
export const ANNOUNCEMENTS_CONFIG = 'announcementsConfig';
export const BIKES = 'bikes';
export const MESSAGES = 'messages';
export const COMMISSIONS = 'commissions';
export const ADMIN = 'admin';
export const PRODUCT_COMPANIES = 'productCompanies';
export const MODULES = 'modules';
export const BANNERS = 'banners';
export const PICKING = 'picking';
export const PAGO_MOVIL = 'PagoMovil';
export const PAGO_MOVIL_FAILS = 'fallos';
export const PAGO_MOVIL_AVAILABLE = 'availablePM';
export const PAGO_MOVIL_PROCESSED = 'PMCheckHistory';
export const PAGO_MOVIL_RETURNED = 'PMDevueltos';
export const CAMPAIGNS = 'smsHistory/bulk/campanas';
export const SCHEDULED_CAMPAIGNS = 'smsHistory/bulk/campanasProgramadas';
export const AMIGA_DEVUELTOS = 'AmigaDevueltos';
