/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Form, Space, Switch, Table } from 'antd';
import Can from 'components/Can';
import { SPECIAL_CATEGORIES } from 'components/Can/modules';
import useSetHeaderProps from 'hooks/useSetHeaderProps';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ShoppingOutlined
} from '@ant-design/icons';
import {
  getSpecialCategoriesData,
  isSpecialCategoriesLoading
} from 'stores/selectors/specialCategories';
import {
  createSpecialCategory,
  getSpecialCategories,
  deleteSpecialCategory,
  updateSpecialCategory
} from 'stores/actions/specialCategories';
import { openModal, closeModal } from 'stores/actions/modal';
import { showNotification } from 'stores/actions/notification';
import ProductsDrawer from './ProductsDrawer';
import SpecialCategoriesForm from './SpecialCategoryForm';
import DeleteSpecialCategory from './DeleteSpecialCategory';

const SpecialCategories = () => {
  const setHeaderProps = useSetHeaderProps();
  const dispatch = useDispatch();
  const isLoading = useSelector(isSpecialCategoriesLoading);
  const [formActive, setFormActive] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentSpecialCategory, setCurrentCategory] = useState({});
  const data = useSelector(getSpecialCategoriesData);
  const [form] = Form.useForm();

  const fetchSpecialCategories = async () => {
    await dispatch(getSpecialCategories());
  };

  const handleProductsList = (id, products) => {
    setSelectedCategoryId(id);
    setShowProducts(!showProducts);
    setProducts(products);
  };

  const handleSpecialCategory = async (body = {}) => {
    const editMode = Object.prototype.hasOwnProperty.call(body, 'id');
    try {
      const existsCoupon = !!data.find(
        (item) => item.name === body.name && body.id !== item.id
      );
      if (existsCoupon) {
        dispatch(
          showNotification({
            type: 'warning',
            message: `Ya existe una categoria especial con el nombre ${body.name}`
          })
        );
        return;
      }
      if (body.products.length === 0) {
        dispatch(
          showNotification({
            type: 'warning',
            message: 'No se puede crear una categoria especial sin productos'
          })
        );
        return;
      }
      const action = () =>
        editMode
          ? dispatch(
              updateSpecialCategory({
                id: body.id,
                data: { products: body.products, name: body.name }
              })
            )
          : dispatch(createSpecialCategory(body));

      await action();
      dispatch(
        showNotification({
          type: 'success',
          content: editMode
            ? 'Categoria especial actualizada !!'
            : 'Categoria especial creada satisfactoriamente'
        })
      );
      setFormActive(false);
      setCurrentCategory({})
      dispatch(getSpecialCategories());
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const handleDeleteSpecialCategory = async (id) => {
    try {
      if (id) {
        await dispatch(deleteSpecialCategory(id));
        dispatch(closeModal());
        dispatch(getSpecialCategories());
      }
    } catch (error) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  const handleUpdateSpecialCategory = async (id, data) => {
    await dispatch(updateSpecialCategory({ id, data }));
    dispatch(getSpecialCategories());
  };

  const confirmationWindow = (current) => {
    const modalContent = {
      component: DeleteSpecialCategory,
      props: {
        cancelForm: () => dispatch(closeModal()),
        handleDeleteSpecialCategory: () =>
          handleDeleteSpecialCategory(current.id),
        current
      }
    };

    dispatch(
      openModal({
        content: modalContent,
        buttons: []
      })
    );
  };

  const handleEdit = (record) => {
    setCurrentCategory(record);
    setFormActive(true);
  };
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Productos',
      dataIndex: 'products',
      align: 'center',
      render: (value, record) => (
        <ShoppingOutlined
          onClick={() => handleProductsList(record.id, value)}
        />
      )
    },
    {
      title: 'Activa',
      dataIndex: 'active',
      align: 'center',
      render: (value, record) => (
        <Space size="middle" key={record.name}>
          <Can I="update" a={SPECIAL_CATEGORIES}>
            <Switch
              defaultChecked={value}
              onChange={(e) =>
                handleUpdateSpecialCategory(record.id, { active: e })
              }
            />
          </Can>
          <Can not I="update" a={SPECIAL_CATEGORIES}>
            {value ? (
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            ) : (
              <CloseCircleOutlined />
            )}
          </Can>
        </Space>
      )
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (record) => (
        <Space size="middle" key={record.name}>
          <EditOutlined onClick={() => handleEdit(record)} />
          <Can I="delete" a={SPECIAL_CATEGORIES}>
            <DeleteOutlined onClick={() => confirmationWindow(record)} />
          </Can>
        </Space>
      )
    }
  ];

  useEffect(() => {
    setHeaderProps({
      title: 'Categorias Especiales',
      extraButtons: [
        <Fragment key={0}>
          <Can I="create" a={SPECIAL_CATEGORIES}>
            <Button type="primary" onClick={() => setFormActive(true)}>
              Crear
            </Button>
          </Can>
        </Fragment>
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSpecialCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          loading={isLoading}
          dataSource={data}
          rowClassName="editable-row"
          bordered
        />
      </Form>
      {showProducts && (
        <ProductsDrawer
          visible={showProducts}
          onClose={handleProductsList}
          categoryId={selectedCategoryId}
          products={products}
          title="Productos"
        />
      )}
      <Drawer
        size="large"
        title="Crear Categoria Especial"
        placement="right"
        onClose={() => {
          setFormActive(false);
          setCurrentCategory({});
        }}
        visible={formActive}
      >
        <SpecialCategoriesForm
          onSubmit={handleSpecialCategory}
          formActive={formActive}
          currentData={currentSpecialCategory}
        />
      </Drawer>
    </>
  );
};

export default SpecialCategories;
