import moment from 'moment';
import { printPaymentTypes } from 'utils/utils';

const getStrInBrackets = (str) => {
  const regex = new RegExp(/\[(.*?)]/);
  const hasBrakets = regex.test(str);
  if (hasBrakets) {
    const matched = regex.exec(str);
    return matched[1] || str;
  }
  return str;
};

const purchaseOrderStatuses = {
  draft: 'Peticion presupuestado',
  sent: 'Solicitud de presupuesto enviada',
  'to approve': 'Para aprobar',
  purchase: 'Pedido de Compra',
  done: 'Bloqueado',
  cancel: 'Cancelada'
};

export const parsedPurchaseResponse = (purchaseOrderLine, purchaseOrder) =>
  purchaseOrderLine.map((item) => ({
    id: item.order_id[1],
    state: purchaseOrderStatuses[item.state],
    provider: item.partner_id[1],
    date_approve:
      purchaseOrder.filter((order) => order.id === item.order_id[1])
        .date_approve || '',
    company: item.company_id[1],
    internalCode: getStrInBrackets(item.product_id[1]),
    product: item.product_id[1],
    description: item.name,
    product_qty: item.product_qty,
    qty_received: item.qty_received,
    total: item.price_subtotal,
    create_date: item.create_date
  }));

export const parsedContactsResponse = (contacts, banks) =>
  contacts.map((item) => ({
    type: item.type || '',
    client_code: item.client_code || '',
    name: item.name || '',
    company_type:
      { person: 'Natural', company: 'Juridica' }[item.company_type] || '',
    street_1: item.street || '',
    zip: item.zip || '',
    city: item.city || '',
    state: item.state_id[1] || '',
    country: item.country_id[1] || '',
    phone: item.phone || '',
    email: item.email || '',
    ciRif: item.identification_id || item.rif || '',
    banks: banks
      .filter((bank) => item.bank_ids.includes(bank.id))
      .map((bank) => bank.bank_name)
      .join(', '),
    accNumbers: banks
      .filter((bank) => item.bank_ids.includes(bank.id))
      .map((bank) => bank.acc_number)
      .join(', '),
    accNames: banks
      .filter((bank) => item.bank_ids.includes(bank.id))
      .map((bank) => bank.acc_holder_name || item.name)
      .join(', '),
    withHoldingAgent: item.islr_withholding_agent ? 'Si' : 'No',
    spn: item.spn ? 'Si' : 'No',
    islrExcent: item.islr_exempt ? 'Si' : 'No',
    holdingAgentIVA: item.wh_iva_agent ? 'Si' : 'No',
    rateAgentIVA: item.wh_iva_rate || '',
    create_date: item.create_date || ''
  }));

export const parsedProductsResponse = (products) =>
  products.map((item) => ({
    code: item.default_code,
    description: item.description,
    company: item.company_id[1] || '',
    unit: item.weight_uom_name || '',
    qty_available: item.qty_available,
    value_qty_avaliable: item.qty_available * item.list_price,
    name: item.name,
    price: item.list_price,
    price_extra: item.price_extra,
    // barcode: item.barcode,
    // active: item.active,
    // image: item.image_1024,
    // currency: item.currency_id[1],
    // template_id: item.product_tmpl_id,
    // volume: item.volume,
    // volume_uom_name: item.volume_uom_name,
    // weight: item.weight,
    // weight_uom_name: item.weight_uom_name,
    // invoice_policy: item.invoice_policy,
    // use_expiration_date: item.use_expiration_date,
    // expiration_time: item.expiration_time,
    // taxes_ids: item.taxes_id,
    // required_recipe: item.x_studio_required_recipe,
    // category: item.categ_id[1] || '',
    // pricelist: item.pricelist_id[1] || '',
    // controlled: item.x_studio_controlled,
    // atc_code: item.x_studio_atc_code || '',
    // therapeutic_classification: item.x_studio_therapeutic_classification || '',
    // laboratory: item.x_studio_laboratory || '',
    // active_ingredient: item.x_studio_active_ingredient || '',
    // product_type: item.x_studio_product_type,
    // recommended: item.x_studio_recommendations,
    // offers: item.x_studio_offers,
    create_date: item.create_date
  }));

/// Factory

export const ordersReportFactory = (
  roadmaps = [],
  routes = [],
  riders = [],
  orders = []
) =>
  orders.map((order) => {
    const odooDataOrder = order?.odooDataOrder;
    const rider = riders.find((riderItem) => riderItem.id === order.riderId);
    const proco = rider?.proco || '';
    const roadmap = roadmaps.find((roadmapItem) =>
      roadmapItem.ordersIds?.includes(order.id)
    );
    const route = routes.find(
      (routeItem) => routeItem.id === order?.addressDetails?.routeId
    );
    const deliverStep = order?.steps?.find(
      (step) => step.label === 'delivered.label'
    );
    const orderTime = moment.unix(order?.date).format('LT') || '';
    const riderAssignedLog = roadmap?.log?.find(
      (log) => log.description === 'riderAssignedManually'
    );
    const riderAssignedDate =
      moment.unix(riderAssignedLog?.date).format('hh:mm A') || '';
    const totalDl = Number(order?.ref || 0).toFixed(2);
    /* Cant of products */
    const products = odooDataOrder?.products || [];
    const roadmapDate = roadmap?.log?.filter((r) => r.type === 'delivered');
    const dateOrders = [];
    if (roadmapDate) {
      roadmapDate.forEach((element, index) => {
        if (index === 0) {
          dateOrders.push({
            order: element.order,
            date: riderAssignedLog?.date
          });
        } else {
          dateOrders.push({
            order: element.order,
            date: roadmapDate[index - 1]?.date
          });
        }
      });
    }

    const dateOrderRider = dateOrders.find(
      (e) => e.order === order.odooDataOrder?.name
    );
    const cantSku = products?.length || 0;
    //
    const totalProducts = products?.reduce(
      (prev, next) => (prev += next.quantity),
      0
    );
    return {
      date: order.odooDataOrder?.create_date?.substring(0, 10) || '',
      riderAssignedDate,
      departureDate: dateOrderRider
        ? moment.unix(dateOrderRider?.date).format('LT')
        : '',
      deliverDate: deliverStep
        ? moment.unix(deliverStep?.date).format('LT')
        : '',
      rider: rider?.name || '',
      dni: rider?.dni || '',
      proco: proco || '',
      route: route?.name || '',
      roadmap: roadmap?.roadmapNumber || '',
      order: order.odooDataOrder?.name || '',
      orderTime,
      client: order?.clientName || '',
      phone: order?.addressDetails?.phone || '',
      totalProducts,
      cantSku,
      amount: order?.odooDataOrder?.amount_total || '',
      totalDl,
      totalBs:
        order?.payment?.methods?.find((e) => e.type === 'bolivarCash')
          ?.amount || '',
      totalCash:
        order?.payment?.methods?.find((e) => e.type === 'cash')?.amount || '',
      couponCode: order?.discount?.code || '',
      couponAmount: order?.discount?.totalBs || '',
      paymentMethod: order?.payment?.type || '',
      paymentValidation: order?.payment?.validated ? 'X' : '',
      delivered: order?.statusCode === 3 ? 'X' : '',
      crossdocking:
        order?.steps?.filter((step) => step.label === 'scanned.label').length >
        1
          ? 'X'
          : '',
      noDelivered: order?.statusCode === 7 ? 'X' : '',
      partialOrder: order?.excludedProducts ? 'X' : '',
      excludedProduts:
        order?.excludedProducts?.products
          ?.map((product) => product?.name)
          .join(', ') || '',
      crossDockingMotive: roadmap?.feedback?.description || '',
      noDeliveredMotive: order?.cancellationReason || '',
      feedback: order?.feedback?.description || '',
      distance: order?.traveledKms || '',
      observations:
        order?.observations
          ?.map((observation) => observation.description)
          .join(', ') || '',
      // I want printDate to be printHour instead.
      printDate: order?.printedInvoiceDate
        ? moment.unix(order?.printedInvoiceDate).format('LT')
        : '-',
      printedManually: order?.printedManually ? 'X' : '-',
      company: order?.company ? order.company : ''
    };
  });

  export const ordersReportReiFactory = (orders = []) =>
    orders.map((order) => {
      const odooDataOrder = order?.odooDataOrder;
      const orderTime = moment.unix(order?.date).format('LT') || '';
      /* Cant of products */
      const products = odooDataOrder?.products || [];
      const address = `${
        (order.addressDetails.state || '') + (order.addressDetails.street || '')
      } ${order.addressDetails.house || ''}`;
      const preWallet = order.user?.preWallet?.credit / 100;
      const company = order.user?.preWallet?.company;
      const payCredit = order?.payment?.methods?.find(
        (e) => e.type === 'credit'
      )?.amount;
      const payCash = order?.payment?.methods?.find(
        (e) => e.type === 'cash'
      )?.amount;
      const payPM = order?.payment?.methods?.find(
        (e) => e.type === 'pagomovil'
      )?.amount;
      const payBs = order?.payment?.methods?.find(
        (e) => e.type === 'bolivarCash'
      )?.amount;
  
      //
      const totalProducts = products?.reduce(
        (prev, next) => (prev += next.quantity),
        0
      );
      return {
        date: order.odooDataOrder?.create_date?.substring(0, 10) || '',
        order: order.odooDataOrder?.name || '',
        orderTime,
        client: order?.user?.name || '',
        dni: order.customizedInvoice?.dni,
        phone: order?.addressDetails?.phone || '',
        totalProducts,
        amount: order?.total || '',
        paymentMethod: order?.payment?.type || '',
        payCredit: payCredit || '',
        payCash: payCash || '',
        payPM: payPM || '',
        payBs: payBs || '',
        addressDetails: address,
        preWallet,
        company: company || '-',
        dollars: order?.ref || '',
        tasa: order?.tasa || '',
        factuName:
          order?.customizedInvoice.fullName || order?.customizedInvoice.name,
        factuPhone: order?.customizedInvoice.phone,
        factuId: `${order?.customizedInvoice.dniType}${order?.customizedInvoice.dni}`
      };
    });

export const ordersReportWalletFactory = (orders = []) =>
  orders.map((order) => {
    const odooDataOrder = order?.odooDataOrder;
    const orderTime = moment.unix(order?.date).format('LT') || '';
    /* Cant of products */
    const products = odooDataOrder?.products || [];
    const address = `${
      (order.addressDetails.state || '') + (order.addressDetails.street || '')
    } ${order.addressDetails.house || ''}`;
    const wallet = order.user?.wallet?.credit / 100;
    const company = order.user?.wallet?.company;
    const payCredit = order?.payment?.methods?.find(
      (e) => e.type === 'credit'
    )?.amount;
    const payCash = order?.payment?.methods?.find(
      (e) => e.type === 'cash'
    )?.amount;
    const payPM = order?.payment?.methods?.find(
      (e) => e.type === 'pagomovil'
    )?.amount;
    const payBs = order?.payment?.methods?.find(
      (e) => e.type === 'bolivarCash'
    )?.amount;

    //
    const totalProducts = products?.reduce(
      (prev, next) => (prev += next.quantity),
      0
    );
    return {
      date: order.odooDataOrder?.create_date?.substring(0, 10) || '',
      order: order.odooDataOrder?.name || '',
      orderTime,
      client: order?.user?.name || '',
      dni: order.customizedInvoice?.dni,
      phone: order?.addressDetails?.phone || '',
      totalProducts,
      amount: order?.total || '',
      paymentMethod: order?.payment?.type || '',
      payCredit: payCredit || '',
      payCash: payCash || '',
      payPM: payPM || '',
      payBs: payBs || '',
      addressDetails: address,
      wallet,
      company: company || '-',
      dollars: order?.ref || '',
      tasa: order?.tasa || '',
      factuName:
        order?.customizedInvoice.fullName || order?.customizedInvoice.name,
      factuPhone: order?.customizedInvoice.phone,
      factuId: `${order?.customizedInvoice.dniType}${order?.customizedInvoice.dni}`
    };
  });

export const ridersReportFactory = (roadmaps = [], riders = [], orders = []) =>
  riders.map((rider) => {
    const assignedOrders = orders.filter((order) => order.riderId === rider.id);
    const assignedRoadmaps = roadmaps.filter(
      (roadmapItem) => roadmapItem.riderId === rider.id
    );

    return {
      rider,
      proco: rider?.proco || '',
      assignedOrders,
      assignedRoadmaps
    };
  });

export const deliveryOrdersReportFactory = (
  roadmaps = [],
  routes = [],
  riders = [],
  orders = []
) =>
  orders
    .filter(
      (item) => item?.statusCode === 3 && item?.status?.type === 'delivery'
    )
    .map((order) => {
      const odooDataOrder = order?.odooDataOrder;
      const rider = riders.find((riderItem) => riderItem.id === order.riderId);
      const roadmap = roadmaps.find((roadmapItem) =>
        roadmapItem?.ordersIds?.includes(order.id)
      );
      const route = routes.find(
        (routeItem) => routeItem.id === roadmap?.routeId
      );
      const departureStep = order?.steps?.find(
        (step) => step?.label === 'onTheWay.label'
      );
      const deliverStep = order?.steps?.find(
        (step) => step?.label === 'delivered.label'
      );
      /* Cant of products */
      const products = odooDataOrder?.products || [];
      const cantSku = products?.length || 0;
      //
      const totalProducts = products.reduce(
        (prev, next) => (prev += next.quantity),
        0
      );
      const paymentType = printPaymentTypes(order?.payment);
      const totalBs = Number(odooDataOrder?.amount_total || 0).toFixed(2);
      const totalDl = Number(order?.ref || 0).toFixed(2);
      const date = odooDataOrder?.create_date?.substring(0, 10) || '';
      const orderTime = moment.unix(order?.date).format('LT') || '';
      return {
        date,
        order: odooDataOrder.name || '',
        roadmap: roadmap?.roadmapNumber || '',
        route: route?.name || '',
        client: order?.clientName || '',
        cantSku,
        totalProducts,
        paymentType,
        totalBs,
        totalDl,
        orderTime,
        invoiceTime: '', // while empty
        rider: rider?.name || '',
        departureDate: departureStep
          ? moment.unix(departureStep?.date).format('LT')
          : '',
        deliverDate: deliverStep
          ? moment.unix(deliverStep?.date).format('LT')
          : ''
      };
    });

export const pickingReportFactory = (orders = [], pickers = [], procos = []) =>
  orders
    .filter((item) => item?.onPacking === 'packed')
    .map((order) => {
      const odooDataOrder = order?.odooDataOrder;
      const orderNumber = odooDataOrder?.name || '';
      const packingDetails = order?.packagingDetail || '';
      const client = order?.clientName || '';
      const proco = procos.find((pr) => pr.id === packingDetails?.proco) || '';
      const procoName = proco?.name;
      const warehouseman =
        pickers.find((pi) => pi.id === packingDetails?.warehouseman) || '';
      const warehouseManName = warehouseman?.name;
      const packingStartTime = moment
        .unix(packingDetails.packingStartTime)
        .format('HH:mm:ss');
      const packingEndTime = moment
        .unix(packingDetails.packingEndTime)
        .format('HH:mm:ss');
      const date = odooDataOrder?.create_date?.substring(0, 10) || '';

      const packingTotalTime =
        packingDetails?.packingEndTime - packingDetails?.packingStartTime || '';

      const formatPackingTotalTime = () => {
        if (!packingTotalTime) return '';
        if (packingTotalTime < 60) {
          return `${Math.round(packingTotalTime)} seg`;
        }
        return `${Math.floor(packingTotalTime / 60)} min ${Math.round(
          packingTotalTime % 60
        )} seg`;
      };

      return {
        date,
        order: orderNumber,
        client,
        packingStartTime,
        packingEndTime,
        packingTotalTime: formatPackingTotalTime(),
        warehouseman: warehouseManName,
        proco: procoName
      };
    });

export const ordersPaymentReportFactory = (
  roadmaps = [],
  routes = [],
  riders = [],
  orders = []
) =>
  orders.map((order) => {
    const odooDataOrder = order?.odooDataOrder;
    const rider = riders.find((riderItem) => riderItem.id === order.riderId);
    const proco = rider?.proco || '';
    const roadmap = roadmaps.find((roadmapItem) =>
      roadmapItem.ordersIds?.includes(order.id)
    );
    const route = routes.find(
      (routeItem) => routeItem.id === order?.addressDetails?.routeId
    );
    const deliverStep = order?.steps?.find(
      (step) => step.label === 'delivered.label'
    );
    const orderTime = moment.unix(order?.date).format('LT') || '';
    const riderAssignedLog = roadmap?.log?.find(
      (log) => log.description === 'riderAssignedManually'
    );
    const riderAssignedDate =
      moment.unix(riderAssignedLog?.date).format('hh:mm A') || '';
    /* Cant of products */
    const products = odooDataOrder?.products || [];
    const roadmapDate = roadmap?.log?.filter((r) => r.type === 'delivered');
    const dateOrders = [];
    if (roadmapDate) {
      roadmapDate.forEach((element, index) => {
        if (index === 0) {
          dateOrders.push({
            order: element.order,
            date: riderAssignedLog?.date
          });
        } else {
          dateOrders.push({
            order: element.order,
            date: roadmapDate[index - 1]?.date
          });
        }
      });
    }

    const dateOrderRider = dateOrders.find(
      (e) => e.order === order.odooDataOrder?.name
    );
    const cantSku = products?.length || 0;
    //
    const totalProducts = products?.reduce(
      (prev, next) => (prev += next.quantity),
      0
    );

    const payments = {
      pagomovil: 0,
      bolivarCash: 0,
      cash: 0,
      binance: 0,
      zelle: 0,
      creditCard: 0,
      credit: 0,
      preCredit: 0
    };

    order.payment.methods.forEach((method) => {
      payments[method.type] = method.amount;
    });

    return {
      date: order.odooDataOrder?.create_date?.substring(0, 10) || '',
      riderAssignedDate,
      departureDate: dateOrderRider
        ? moment.unix(dateOrderRider?.date).format('LT')
        : '',
      deliverDate: deliverStep
        ? moment.unix(deliverStep?.date).format('LT')
        : '',
      rider: rider?.name || '',
      dni: rider?.dni || '',
      proco: proco || '',
      route: route?.name || '',
      roadmap: roadmap?.roadmapNumber || '',
      order: order.odooDataOrder?.name || '',
      orderTime,
      client: order?.clientName || '',
      phone: order?.addressDetails?.phone || '',
      totalProducts,
      cantSku,
      pagomovil: payments.pagomovil,
      bolivarCash: payments.bolivarCash,
      cash: payments.cash,
      binance: payments.binance,
      zelle: payments.zelle,
      creditCard: payments.creditCard,
      credit: payments.credit,
      preCredit: payments.preCredit,
      amount: order?.total || '',
      tasa: order?.tasa || '',
      couponCode: order?.discount?.code || '',
      couponAmount: order?.discount?.totalBs || '',
      paymentMethod: order?.payment?.type || '',
      paymentValidation: order?.payment?.validated ? 'X' : '',
      delivered: order?.statusCode === 3 ? 'X' : '',
      crossdocking:
        order?.steps?.filter((step) => step.label === 'scanned.label').length >
        1
          ? 'X'
          : '',
      noDelivered: order?.statusCode === 7 ? 'X' : '',
      partialOrder: order?.excludedProducts ? 'X' : '',
      excludedProduts:
        order?.excludedProducts?.products
          ?.map((product) => product?.name)
          .join(', ') || '',
      crossDockingMotive: roadmap?.feedback?.description || '',
      noDeliveredMotive: order?.cancellationReason || '',
      feedback: order?.feedback?.description || '',
      distance: order?.traveledKms || '',
      observations:
        order?.observations
          ?.map((observation) => observation.description)
          .join(', ') || '',
      // I want printDate to be printHour instead.
      printDate: order?.printedInvoiceDate
        ? moment.unix(order?.printedInvoiceDate).format('LT')
        : '-',
      printedManually: order?.printedManually ? 'X' : '-',
      company: order?.company ? order.company : ''
    };
  });
