import {
  ACCOUNT,
  HOME,
  PRODUCTS,
  MIN_MAX,
  ROLES,
  USERS,
  ROUTES,
  RIDERS,
  // Finances
  FINANCE,
  FINANCE_PAGO_MOVIL,
  FINANCE_VALIDATE_PAY,
  RIDERS_PROCOS,
  ORDERS,
  // ADDRESSES,
  ROADMAPS,
  DEPARTMENTS,
  CONFIG,
  CONFIG_MESSAGES,
  NOTIFICATIONS,
  COMMISSIONS_BY_RIDER,
  CONFIG_COMMISSIONS,
  ORDERS_PICKUP_CONFIRMATION,
  REPORTS,
  REPORTSG,
  // ORDERS_PAYMENTS_CONFIRMATION,
  COMMERCE_TO_PERSON,
  COMMERCE_TO_PERSON_REPORT,
  MARKETING,
  BANNERS,
  MARKETING_EMAIL,
  COUPONS,
  STORAGE,
  STORAGE_PACKING,
  STORAGE_BILLING,
  SPECIAL_CATEGORIES,
  CART_VALIDATION,
  CONFIG_ANNOUNCEMENTS,
  STORAGE_PHARMACY,
  STORAGE_PROCOS,
  STORAGE_STATUS_PICKING,
  MARKETING_SMS,
  PICTURES,
  WALLET,
  REI,
  REFERRED,
  DASH_ORDERS
} from 'constants/routes';
import {
  PieChartOutlined,
  TeamOutlined,
  ClusterOutlined,
  TrademarkCircleOutlined,
  CarOutlined,
  AuditOutlined,
  ScheduleOutlined,
  HddOutlined,
  SettingFilled,
  MessageOutlined,
  NotificationOutlined,
  DollarCircleOutlined,
  ProfileOutlined,
  FileSyncOutlined,
  UserOutlined,
  FileImageOutlined,
  // FileSearchOutlined,
  ShoppingCartOutlined,
  SendOutlined,
  BarcodeOutlined,
  AppstoreOutlined,
  TagOutlined,
  FundProjectionScreenOutlined,
  LineChartOutlined,
  PhoneOutlined,
  PictureOutlined,
  WalletOutlined
} from '@ant-design/icons';

import { MdSportsMotorsports } from 'react-icons/md';
import { FiActivity, FiPackage, FiSidebar } from 'react-icons/fi';
import { RiBillLine } from 'react-icons/ri';
import { BsBag } from 'react-icons/bs';

export const commonModules = ['home', 'myAccount'];

export const isCommonModule = (module) => commonModules.includes(module);

const menu = [
  {
    key: HOME,
    icon: PieChartOutlined,
    label: 'home',
    children: [],
    permission: 'home'
  },
  {
    key: DASH_ORDERS,
    icon: PieChartOutlined,
    label: 'dash-orders',
    children: [],
    permission: 'dash-orders'
  },
  {
    key: ACCOUNT,
    icon: UserOutlined,
    label: 'myAccount',
    children: [],
    permission: 'myAccount'
  },
  {
    key: USERS,
    icon: TeamOutlined,
    label: 'users',
    children: [],
    permission: 'users'
  },
  {
    key: PRODUCTS,
    icon: BsBag,
    label: 'products',
    children: [],
    permission: 'products'
  },
  {
    key: MIN_MAX,
    icon: LineChartOutlined,
    label: 'minmax',
    children: [],
    permission: 'minmax'
  },
  {
    key: REFERRED,
    icon: LineChartOutlined,
    label: 'referred',
    children: [],
    permission: 'referred'
  },
  {
    key: CART_VALIDATION,
    icon: ShoppingCartOutlined,
    label: 'cart-validation',
    children: [],
    permission: 'cart-validation'
  },
  {
    key: 'orders',
    icon: AuditOutlined,
    label: 'orders-parent',
    permission: 'orders',
    children: [
      {
        key: ORDERS,
        icon: AuditOutlined,
        label: 'orders',
        children: [],
        permission: 'orders'
      },
      {
        key: ORDERS_PICKUP_CONFIRMATION,
        icon: ProfileOutlined,
        label: 'confirmation-pickup',
        children: [],
        permission: 'confirmation-pickup'
      }
      // {
      // key: ORDERS_PAYMENTS_CONFIRMATION,
      // icon: FileSearchOutlined,
      // label: 'confirmation-payments',
      // children: [],
      // permission: 'confirmation-payments'
      // }
    ]
  },
  // {
  // key: ADDRESSES,
  // icon: HomeOutlined,
  // label: 'addresses',
  // children: [],
  // permission: 'addresses'
  // },
  {
    key: 'finance',
    icon: DollarCircleOutlined,
    label: 'finance-parent',
    permission: 'finance',
    children: [
      {
        key: FINANCE,
        icon: ScheduleOutlined,
        label: 'assign-price',
        children: [],
        permission: 'finance'
      },
      {
        key: FINANCE_PAGO_MOVIL,
        icon: ScheduleOutlined,
        label: 'finance-pago-movil',
        children: [],
        permission: 'finance'
      },
      {
        key: FINANCE_VALIDATE_PAY,
        icon: ScheduleOutlined,
        label: 'finance-validate-pay',
        children: [],
        permission: 'finance'
      },
      {
        key: WALLET,
        icon: WalletOutlined,
        label: 'wallet',
        children: [],
        permission: 'finance'
      },
      {
        key: REI,
        icon: WalletOutlined,
        label: 'preWallet',
        children: [],
        permission: 'finance'
      },
      {
        key: COMMERCE_TO_PERSON,
        icon: ScheduleOutlined,
        label: 'commerce-to-person',
        children: [],
        permission: 'commerce-to-person'
      },
      {
        key: COMMERCE_TO_PERSON_REPORT,
        icon: FileImageOutlined,
        label: 'reports',
        children: [],
        permission: 'c2p-report'
      }
    ]
  },
  {
    key: STORAGE,
    icon: AppstoreOutlined,
    label: 'storage',
    permission: 'storage',
    children: [
      {
        key: STORAGE_PACKING,
        icon: FiPackage,
        label: 'packing',
        children: [],
        permission: 'packing'
      },
      {
        key: STORAGE_STATUS_PICKING,
        icon: AuditOutlined,
        label: 'picking-status',
        children: [],
        permission: 'picking-status'
      },
      {
        key: STORAGE_BILLING,
        icon: RiBillLine,
        label: 'billing',
        children: [],
        permission: 'billing'
      },
      {
        key: STORAGE_PHARMACY,
        icon: FiActivity,
        label: 'pharmacy',
        children: [],
        permission: 'pharmacy'
      },
      {
        key: STORAGE_PROCOS,
        icon: RiBillLine,
        label: 'procos',
        children: [],
        permission: 'billing'
      }
    ]
  },
  {
    key: ROADMAPS,
    icon: ScheduleOutlined,
    label: 'roadmaps',
    children: [],
    permission: 'roadmaps'
  },
  {
    key: SPECIAL_CATEGORIES,
    icon: TagOutlined,
    label: 'special-categories',
    children: [],
    permission: 'special-categories'
  },
  {
    key: COUPONS,
    icon: BarcodeOutlined,
    label: 'coupons',
    children: [],
    permission: 'coupons'
  },
  {
    key: MARKETING,
    icon: FundProjectionScreenOutlined,
    label: 'marketing',
    permission: 'marketing',
    children: [
      {
        key: BANNERS,
        icon: FileImageOutlined,
        label: 'banners',
        children: [],
        permission: 'banners'
      },
      {
        key: MARKETING_EMAIL,
        icon: SendOutlined,
        label: 'marketing-email',
        children: [],
        permission: 'marketing-email'
      },
      {
        key: MARKETING_SMS,
        icon: PhoneOutlined,
        label: 'marketing-sms',
        children: [],
        permission: 'marketing-sms'
      },
      {
        key: CONFIG_ANNOUNCEMENTS,
        icon: FiSidebar,
        label: 'modal-config',
        children: [],
        permission: 'modal-config'
      }
    ]
  },
  {
    key: 'riders',
    icon: TrademarkCircleOutlined,
    label: 'riders-parent',
    permission: 'riders',
    children: [
      {
        key: RIDERS,
        icon: MdSportsMotorsports,
        label: 'riders',
        children: [],
        permission: 'riders'
      },
      {
        key: RIDERS_PROCOS,
        icon: DollarCircleOutlined,
        label: 'procos',
        children: [],
        permission: 'procos'
      },
      {
        key: COMMISSIONS_BY_RIDER,
        icon: DollarCircleOutlined,
        label: 'commissionsByRider',
        children: [],
        permission: 'commissionsByRider'
      }
    ]
  },
  {
    key: ROUTES,
    icon: CarOutlined,
    label: 'routes',
    children: [],
    permission: 'routes'
  },
  {
    key: NOTIFICATIONS,
    icon: NotificationOutlined,
    label: 'notifications',
    children: [],
    permission: 'notifications'
  },
  {
    key: ROLES,
    icon: ClusterOutlined,
    label: 'roles',
    children: [],
    permission: 'roles'
  },
  {
    key: REPORTS,
    icon: FileSyncOutlined,
    label: 'reports',
    children: [],
    permission: 'reports'
  },
  {
    key: REPORTSG,
    icon: FileSyncOutlined,
    label: 'reportsG',
    children: [],
    permission: 'reportsG'
  },
  {
    key: CONFIG,
    icon: SettingFilled,
    label: 'config',
    permission: 'config',
    children: [
      {
        key: DEPARTMENTS,
        icon: HddOutlined,
        label: 'departments',
        children: [],
        permission: 'departments'
      },
      {
        key: CONFIG_MESSAGES,
        icon: MessageOutlined,
        label: 'messages',
        children: [],
        permission: 'messages'
      },
      {
        key: CONFIG_COMMISSIONS,
        icon: DollarCircleOutlined,
        label: 'commissions',
        children: [],
        permission: 'commissions'
      }
    ]
  },
  {
    key: PICTURES,
    icon: PictureOutlined,
    label: 'pictures',
    children: [],
    permission: 'pictures'
  }
  // {
  //   key: INVOICES,
  //   icon: ContainerOutlined,
  //   label: 'invoices',
  //   children: [],
  //   permission: 'invoices'
  // },
];

export default menu;
